<template>
  <div style="height: inherit">
    <b-row>
      <b-col>
        <b-card
          title="Settings"
        >
          <b-row>
            <b-col md="6">
              <b-row align-v="center">
                <b-col
                  md="6"
                  class="mt-1"
                >
                  <span class="font-weight-bold">{{ $t('Theme') }}</span>
                </b-col>
                <b-col
                  md="6"
                  class="mt-1"
                >
                  <dark-Toggler class="d-block" />
                </b-col>
                <b-col
                  md="6"
                  class="mt-1"
                >
                  <span class="font-weight-bold">{{ $t('Language') }}</span>
                </b-col>
                <b-col
                  md="6"
                  class="mt-1"
                >
                  <ul style="list-style-type: none; padding-left: 0; display:inline-block;">
                    <locale />
                  </ul>
                </b-col>
                <b-col
                  md="6"
                  class="mt-1"
                >
                  <span class="font-weight-bold">{{ $t('Units') }}</span>
                </b-col>
                <b-col
                  md="6"
                  class="mt-1"
                >
                  <b-button-group
                    class=""
                  >
                    <b-button
                      v-for="btn in unitTypes"
                      :key="btn.value"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      :pressed="btn.value === units"
                      @click="units = btn.value"
                    >
                      {{ btn.text }}
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButtonGroup, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    DarkToggler,
    Locale,
    BButtonGroup,
    BButton,
  },
  data() {
    return {
      units: 'metric',
    }
  },
  computed: {
    unitTypes() {
      return [
        {
          text: this.$t('Meters'),
          value: 'metric',
        },
        {
          text: this.$t('Yards'),
          value: 'imperial',
        },
      ]
    },
  },
  watch: {
    units(newVal) {
      this.$store.commit('spider/setUnits', newVal)
    },
  },
  created() {
    this.units = this.$store.state.spider.units
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
